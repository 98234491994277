import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {ThemeProvider} from '@gravity-ui/uikit';
import {AuthWrapper, Login, PersonalAccountLayout, RemindPass, Wrapper} from '@components';
import {AppContext, AppState, loadStateFromLS} from '@app/context';
import {createEmitter} from '@app/events';
import {
    BalancePage,
    BasketPage,
    ContactsPage,
    HomePage,
    IndexPage,
    LevelPricePage,
    OrdersPage,
    PersonInfoPage,
    ProfilePage,
    SearchPage,
    UserAddressPage
} from '@pages';
import {CustomToasterProvider} from 'app/Providers';

const App = () => {
    const [state, setState] = React.useState<AppState>(loadStateFromLS());

    return (
        <AppContext.Provider value={{state: state, emit: createEmitter(setState)}}>
            <ThemeProvider theme="light">
                <CustomToasterProvider>
                    <Routes>
                        <Route path="/" element={<Wrapper />}>
                            <Route path="" index element={<IndexPage />} />
                            <Route path="/login" element={<Login />}></Route>
                            <Route path="/remind-pass" element={<RemindPass />}></Route>
                            <Route path="*"></Route>
                        </Route>

                        <Route element={<AuthWrapper />}>
                            <Route element={<PersonalAccountLayout />}>
                                <Route path="/home" element={<HomePage />} />
                                <Route path="/search" element={<SearchPage />} />
                                <Route path="/basket" element={<BasketPage />} />
                                <Route path="/orders" element={<OrdersPage />} />
                                <Route path="/profile" element={<ProfilePage />}>
                                    <Route index element={<PersonInfoPage />} />
                                    <Route path="person-info" index element={<PersonInfoPage />} />
                                    <Route path="user-address" element={<UserAddressPage />} />
                                    <Route path="price-level" element={<LevelPricePage />} />
                                    <Route path="contacts" element={<ContactsPage />} />
                                </Route>
                                <Route path="/balance" element={<BalancePage />} />
                            </Route>
                        </Route>
                    </Routes>
                </CustomToasterProvider>
            </ThemeProvider>
        </AppContext.Provider>
    );
};

export default App;
