import {useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Icon, Tooltip} from '@gravity-ui/uikit';
import {Copy, Envelope} from '@gravity-ui/icons';
import Whatsapp from '@assets/whatsapp-icon.svg';
import {TooltipCopy} from '@components';
import {AppContext} from '@app/context';

import './ManagerInfo.scss';

const b = block('manager-info');

export const ManagerInfo: React.FC = () => {
    const {state: {clientDetails}} = useContext(AppContext);

    return (
        <div className={b()}>
            <Text variant='header-1'>Менеджеры</Text>
            {clientDetails?.mop && Object.keys(clientDetails.mop).length > 0 ?
                <div className={b('manager')}>
                    <Text variant='subheader-2'>Ваш менеджер:</Text>
                    <Text className={b('firstname')} variant='body-2'>{clientDetails.mop.name || ''}</Text>
                    <TooltipCopy copyText={clientDetails.mop.email || ''}>
                        <Icon className={b('icon')} data={Envelope} />
                        <Text variant='body-1'>{clientDetails.mop.email || ''}</Text>
                    </TooltipCopy>
                    <div className={b('content')}>
                        <TooltipCopy copyText={clientDetails.mop.phone || ''}>
                            <Icon className={b('icon')} data={Copy} />
                            <Text variant='body-1'>{clientDetails.mop.phone || ''}</Text>
                        </TooltipCopy>
                        <Tooltip
                            content={
                                <Text className={b('whatsapp-tooltip-content')}>
                                    Откроется Whatsapp в браузере
                                </Text>
                            }
                            placement="top-end"
                            contentClassName={b('whatsapp-tooltip')}
                            className={b('whatsapp-tooltip-container')}
                            openDelay={0}
                            closeDelay={0}
                            disabled={false}
                        >
                            <a href={`https://web.whatsapp.com/send?phone=${clientDetails.mop.phone || ''}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={b('link')}>
                                <img src={Whatsapp} alt="Whatsapp" />
                            </a>
                        </Tooltip>
                    </div>
                </div>
                :
                null
            }
            {clientDetails?.mrk && Object.keys(clientDetails.mrk).length > 0?
                <div className={b('manager')}>
                    <Text variant='subheader-2'>Специалист клиентской поддержки:</Text>
                    <Text className={b('firstname')} variant='body-2'>{clientDetails.mrk.name || ''}</Text>
                    <TooltipCopy copyText={clientDetails.mrk.email || ''}>
                        <Icon className={b('icon')} data={Envelope} />
                        <Text variant='body-1'>{clientDetails.mrk.email || ''}</Text>
                    </TooltipCopy>
                    <div className={b('content')}>
                        <TooltipCopy copyText={clientDetails.mrk.phone || ''}>
                            <Icon className={b('icon')} data={Copy} />
                            <Text variant='body-1'>{clientDetails.mrk.phone || ''}</Text>
                        </TooltipCopy>
                        <Tooltip
                            content={
                                <Text className={b('whatsapp-tooltip-content')}>
                                    Откроется Whatsapp в браузере
                                </Text>
                            }
                            placement="top-end"
                            contentClassName={b('whatsapp-tooltip')}
                            className={b('whatsapp-tooltip-container')}
                            openDelay={0}
                            closeDelay={0}
                            disabled={false}
                        >
                            <a href={`https://web.whatsapp.com/send?phone=${clientDetails.mrk.phone || ''}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={b('link')}>
                                <img src={Whatsapp} alt="Whatsapp" />
                            </a>
                        </Tooltip>
                    </div>
                </div>
                :
                null
            }
            {clientDetails?.rop && Object.keys(clientDetails.rop).length > 0 ?
                <div className={b('manager')}>
                    <Text variant='subheader-2'>Руководитель отдела продаж:</Text>
                    <Text className={b('firstname')} variant='body-2'>{clientDetails.rop.name || ''}</Text>
                    <TooltipCopy copyText={clientDetails.rop.email || ''}>
                        <Icon className={b('icon')} data={Envelope} />
                        <Text variant='body-1'>{clientDetails.rop.email || ''}</Text>
                    </TooltipCopy>
                    <div className={b('content')}>
                        <TooltipCopy copyText={clientDetails.rop.phone || ''}>
                            <Icon className={b('icon')} data={Copy} />
                            <Text variant='body-1'>{clientDetails.rop.phone || ''}</Text>
                        </TooltipCopy>
                        <Tooltip
                            content={
                                <Text className={b('whatsapp-tooltip-content')}>
                                    Откроется Whatsapp в браузере
                                </Text>
                            }
                            placement="top-end"
                            contentClassName={b('whatsapp-tooltip')}
                            className={b('whatsapp-tooltip-container')}
                            openDelay={0}
                            closeDelay={0}
                            disabled={false}
                        >
                            <a href={`https://web.whatsapp.com/send?phone=${clientDetails.rop.phone || ''}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={b('link')}>
                                <img src={Whatsapp} alt="Whatsapp" />
                            </a>
                        </Tooltip>
                    </div>
                </div>
                :
                null
            }
        </div>
    );
};
